  /* ABOUT */

    h2 {
    font-size: 25px;
    text-align: left;
    padding-top: 50px;
    padding-left: 50px;
    width: 500px;
    margin:auto;
    border-top: .5px solid rgb(255, 255, 255);
    border-left: .5px solid rgb(255, 255, 255);
    border-right: .5px solid rgb(255, 255, 255);
  }

  .box {
    width: 500px;
    margin:auto;
    border-left: .5px solid rgb(255, 255, 255);
    border-right: .5px solid rgb(255, 255, 255);
    border-bottom:  .5px solid rgb(255, 255, 255);
    padding-bottom:50px;
  }

  .aboutContainer {
    width: 750px;
    margin: 0 auto;
    /* padding: 50px; */
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }

/* .aboutContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
} */


@media only screen and (max-width: 915px) {  

.aboutContainer,
.introP h2, .box {
  width: 100%;
}

.aboutContainer {
  text-align: left;
}

.box {
  padding: 0px;
}

.introP h2 {
  padding: 15px;
  text-align: left;
}
}