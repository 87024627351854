
  /* GLOBAL LAYOUT */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
  }

  body {
    background-color: rgb(0, 0, 0);
    font-family: 'Roboto Mono', monospace;
    font-size: 15px;
    line-height: 1.7em;
  }


main, .main {
  margin: 50px;
}

  h1, h2, h3 , h4, h5 , h6 {

    font-family: 'Roboto', sans-serif;
    line-height: 1.2em;
    letter-spacing: .05em;
  }

  p {
    padding: 15px;
  }

  a {
    color: inherit;
    text-decoration: none;
    --thickness: .08em;
    --strike: 0;
    
    background: linear-gradient(90deg, transparent, currentColor 0) no-repeat 
                right center / calc(var(--strike) * 100%) var(--thickness);
  
    transition: background-size .8s ease;
    /* font: 25px Arial;
    padding: 0 .2em; */
  }

  a:hover{
    /* text-decoration: black solid line-through 1px; */
    --strike: 1; /* "1" means "true" (show the strike line) */
    background-position-x: center;
  
  }

  h1{
    /* text-transform: uppercase; */
    font-weight: 900;
    font-size: 50px;
  }

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
  }

  .logo {
      font-size: 30px;
      /* text-transform: uppercase; */
      font-weight: 900;
  }

  .logo a:first-child:hover {
    --strike: 0;
  }
  
  .bjlogo {
    width: 60px;
    text-decoration: none;
  }

/* a{
      text-decoration: none;
  } */
  .navLinks a {
    padding: 20px;
    text-transform: lowercase;
  }

  footer {
    margin: 50px;
    display: flex;
    align-items: center;
  }
  
  footer a,h4 {
    padding-right: 40px
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 50px;
    margin-top: 50px;
    margin-bottom: 50px;
  }


/* footer, nav {
  opacity: 100%!important;
} */


@media only screen and (max-width: 915px) { 

  main {
    margin: 35px;
  } 

  h1 {
    font-size: 40px;
  }

  .navLinks {
    display: flex;
    flex-direction: column;
  }

  .bjlogo {
    width: 50px;
    margin-right: 10px;
  }

  .navLinks a{
    padding: 3px;
    text-transform: lowercase;
    text-decoration: 0.5px underline;
    font-size: 15px;
}

footer a, h4 {
  padding-bottom: 15px;
}

footer {
  padding-left: 50px;
  display: flex;
  flex-direction: column;
}
}
